import React from "react";
import styles from "./socials.module.scss";

function Facebook(props) {
  return (
    <a
      href='https://www.facebook.com/n5now'
      rel='noopener noreferrer'
      target='_blank'
    >
      <svg
        width={9}
        height={16}
        viewBox='0 0 9 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.835 2.892a8.259 8.259 0 00-1.396-.152c-1.003 0-1.068.436-1.068 1.134v1.242h2.508L7.66 7.69H5.37v7.827H2.232V7.69H.617V5.116h1.614V3.525c0-2.18 1.025-3.402 3.598-3.402.894 0 1.548.13 2.398.305l-.392 2.464z'
          fill='#fff'
        />
      </svg>
    </a>
  );
}

function Instagram(props) {
  return (
    <a
      href='https://www.instagram.com/n5now_/'
      rel='noopener noreferrer'
      target='_blank'
    >
      <svg
        width={18}
        height={18}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M8.686 1.937c2.242 0 2.508.008 3.393.049.819.037 1.263.174 1.56.289.391.152.67.334.965.628.294.294.476.574.628.965.115.296.252.741.289 1.56.04.885.049 1.15.049 3.393 0 2.242-.009 2.507-.05 3.393-.036.818-.173 1.263-.288 1.559a2.601 2.601 0 01-.628.965 2.603 2.603 0 01-.966.629c-.296.114-.74.251-1.559.289-.885.04-1.15.049-3.393.049-2.242 0-2.508-.009-3.393-.05-.819-.037-1.263-.173-1.56-.288a2.602 2.602 0 01-.965-.629 2.601 2.601 0 01-.628-.965c-.115-.296-.252-.74-.289-1.56-.04-.885-.049-1.15-.049-3.392s.009-2.508.049-3.393c.037-.819.174-1.264.29-1.56.151-.391.333-.671.627-.965.294-.294.574-.476.966-.628.296-.115.74-.252 1.559-.29.885-.04 1.15-.048 3.393-.048zm0-1.513c-2.28 0-2.566.01-3.462.05-.894.041-1.504.183-2.038.39a4.116 4.116 0 00-1.488.97A4.116 4.116 0 00.73 3.32C.522 3.855.38 4.465.34 5.36.299 6.254.29 6.54.29 8.82c0 2.28.009 2.566.05 3.462.04.893.182 1.504.39 2.038.214.552.502 1.02.968 1.487.467.467.936.754 1.488.969.534.207 1.144.35 2.038.39.896.04 1.181.05 3.462.05 2.28 0 2.566-.01 3.462-.05.894-.04 1.504-.183 2.038-.39a4.116 4.116 0 001.488-.969c.466-.467.753-.935.968-1.487.208-.534.35-1.145.39-2.038.041-.896.05-1.182.05-3.462 0-2.28-.009-2.567-.05-3.462-.04-.894-.182-1.504-.39-2.039a4.117 4.117 0 00-.968-1.487 4.116 4.116 0 00-1.488-.968c-.534-.208-1.144-.35-2.038-.39-.896-.042-1.182-.051-3.462-.051z'
          fill='#fff'
        />
        <path
          d='M8.69 4.513a4.312 4.312 0 100 8.623 4.312 4.312 0 000-8.623zm0 7.11a2.799 2.799 0 110-5.597 2.799 2.799 0 010 5.598zM14.18 4.34a1.008 1.008 0 11-2.016 0 1.008 1.008 0 012.015 0z'
          fill='#fff'
        />
      </svg>
    </a>
  );
}

function Twitter(props) {
  return (
    <a
      href='https://twitter.com/N5Now'
      rel='noopener noreferrer'
      target='_blank'
    >
      <svg
        width={18}
        height={15}
        viewBox='0 0 18 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.661 2.138a6.88 6.88 0 01-1.979.542A3.454 3.454 0 0017.197.774a6.9 6.9 0 01-2.188.837 3.446 3.446 0 00-5.87 3.142 9.78 9.78 0 01-7.102-3.6 3.427 3.427 0 00-.466 1.732c0 1.195.607 2.25 1.532 2.868a3.435 3.435 0 01-1.56-.431v.043a3.45 3.45 0 002.763 3.38 3.462 3.462 0 01-1.556.058 3.45 3.45 0 003.218 2.393 6.914 6.914 0 01-5.1 1.426 9.753 9.753 0 005.28 1.548c6.338 0 9.803-5.25 9.803-9.802 0-.15-.003-.298-.01-.446a7.016 7.016 0 001.72-1.784z'
          fill='#fff'
        />
      </svg>
    </a>
  );
}

function Linkedin(props) {
  return (
    <a
      href='https://www.linkedin.com/company/n5now/'
      rel='noopener noreferrer'
      target='_blank'
    >
      <svg
        width={16}
        height={15}
        viewBox='0 0 16 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.482 1.721c0 .871-.664 1.576-1.701 1.576-.997 0-1.66-.705-1.64-1.576C.121.808.784.123 1.801.123s1.66.685 1.68 1.598zM.224 14.691V4.543h3.154V14.69H.224v.001zM5.908 7.781c0-1.265-.042-2.344-.084-3.237h2.74l.145 1.39h.063c.415-.643 1.452-1.618 3.133-1.618 2.075 0 3.631 1.37 3.631 4.357v6.02h-3.154V9.067c0-1.308-.456-2.2-1.597-2.2-.872 0-1.39.602-1.598 1.183-.083.208-.124.498-.124.789v5.852H5.909v-6.91h-.001z'
          fill='#fff'
        />
      </svg>
    </a>
  );
}

const SocialButton = ({ social }) => {
  const handleSocial = () => {
    switch (social) {
      case "facebook":
        return <Facebook />;
      case "instagram":
        return <Instagram />;
      case "twitter":
        return <Twitter />;
      case "linkedin":
        return <Linkedin />;
      default:
        return null;
    }
  };
  return <div className={styles.social}>{handleSocial()}</div>;
};

export default SocialButton;
