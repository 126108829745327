import React from "react";
import styles from "./Footer.module.scss";

import phone from "../../../assets/image/phone.png";
import assist1 from "../../../assets/image/assist1.png";
import assist2 from "../../../assets/image/assist2.png";
import assist3 from "../../../assets/image/assist3.png";
import assist4 from "../../../assets/image/assist4.png";
import n5assist from "../../../assets/image/n5assist.png";
import logo from "../../../assets/svg/logoFooter.svg";
import SocialButton from "@atoms/SocialButton";
import { Link } from "gatsby";
import { scrollTo } from "../../../utils/scrollTo";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

const Footer = ({ openChat }) => {
  const { t } = useTranslation();  
  const { language } = useI18next();

  const getConocenosFromLanguage = (lang) => {
    if(lang === "es"){
      return "https://blog.n5now.com/conocenos/";
    } else if (lang === "en") {
      return "https://blog.n5now.com/en/about-us/";
    } else if (lang === "pr") {
      return "https://blog.n5now.com/pt-br/conheca-nos/";
    }
    return ;
  }

  const getDownloadFromLanguage = (lang) => {
    if(lang === "es"){
      return "https://blog.n5now.com/descargables/";
    } else if (lang === "en") {
      return "https://blog.n5now.com/en/downloads/";
    } else if (lang === "pr") {
      return "https://blog.n5now.com/pt-br/download/";
    }
    return ;
  }

  const getBlogFromLanguage = (lang) => {
    if(lang === "es"){
      return "https://blog.n5now.com/";
    } else if (lang === "en") {
      return "https://blog.n5now.com/en";
    } else if (lang === "pr") {
      return "https://blog.n5now.com/pt-br/";
    }
    return ;
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_bg} />
      <section className={styles.footer_container}>
        <div className={styles.footer_container_title}>
          <h2>{t("FOOTER.TOOGOOD")}</h2>
          <h2>{t("FOOTER.CONTACT")}</h2>
        </div>
        <div className={styles.footer_container_phone}>
          <img src={phone} alt='Phone Chat' loading="lazy" />

          <div className={styles.footer_container_phone_content}>
            <div className={styles.footer_container_phone_content_images}>
              <figure>
                <img src={assist1} alt='Assistant' loading="lazy" />
              </figure>
              <figure>
                <img src={assist2} alt='Assistant' loading="lazy" />
              </figure>
              <figure>
                <img src={assist3} alt='Assistant' loading="lazy" />
              </figure>
              <figure>
                <img src={assist4} alt='Assistant' loading="lazy" />
              </figure>
              <figure>
                <img src={n5assist} alt='N5 Assistants Logo' loading="lazy" />
              </figure>
            </div>
            <p className={styles.footer_container_phone_content_title}>
              {t("FOOTER.4HUMAN")}
            </p>
            <p className={styles.footer_container_phone_content_subtitle}>
              {t("FOOTER.INSTANT")}
            </p>
            <button
              onClick={openChat}
              style={{ color: "white", cursor: "pointer" }}
              className='btnprimary'
            >
              {t("FOOTER.SPEAK")}
            </button>
          </div>
        </div>
      </section>
      <section className={styles.footer_container}>
        <div className={styles.footer_container_logo}>
          <img src={logo} alt='N5 Logo' loading="lazy"/>
        </div>
        <div className={styles.footer_container_links}>
          <div className=''>
            <p className='fs18 mb10'>{t("FOOTER.PLATFORM.PLATFORM")}</p>
            <ul className={styles.footerList}>
              <li>
                <Link to='/platform/'>{t("FOOTER.PLATFORM.HOWITWORKS")}</Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollTo("/platform/", "expertise", 100);
                  }}
                >
                  {t("FOOTER.PLATFORM.EXPERTISE")}
                </button>
              </li>
            </ul>
          </div>
          <div className=''>
            <p className='fs18 mb10'>{t("FOOTER.SERVICES.SERVICES")}</p>
            <ul className={styles.footerList}>
              <li>
                <button
                  onClick={() => {
                    scrollTo("/services/", "consulting", 100);
                  }}
                >
                  {t("FOOTER.SERVICES.CONSULTING")}
                </button>                
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollTo("/services/", "services", 100);
                  }}
                >
                  {t("FOOTER.SERVICES.SERVICE")}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollTo("/services/", "monitor", 100);
                  }}
                >
                  {t("FOOTER.SERVICES.MONITOR")}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollTo("/services/", "methodology", 100);
                  }}
                >
                  {t("FOOTER.SERVICES.METHODOLOGY")}
                </button>
              </li>
            </ul>
          </div>
          <div className=''>
            <p className='fs18 mb10'>{t("FOOTER.CONTENT.CONTENT")}</p>
            <ul className={styles.footerList}>
              <li>                                
                <a href={getConocenosFromLanguage(language)}>{t("FOOTER.CONTENT.VIDEOS")}</a>
              </li>
              <li>                                
                <a href={getBlogFromLanguage(language)}>{t("FOOTER.CONTENT.BLOG")}</a>
              </li>
              <li>                                
                <a href={getDownloadFromLanguage(language)}>{t("FOOTER.CONTENT.DOWNLOAD")}</a>
              </li>
            </ul>
          </div>
          <div className=''>
            <p className='fs18 mb10'>{t("FOOTER.WORKWITHUS.WORKWITHUS")}</p>
            <ul className={styles.footerList}>
              <li>                
                <button
                  onClick={() => {
                    scrollTo("/work/", "thisIsUs", 100);
                  }}
                >
                  {t("FOOTER.WORKWITHUS.THISISUS")}
                </button>
              </li>
              <li>
                <Link to='/work#offices'>{t("FOOTER.WORKWITHUS.OFFICES")}</Link>
              </li>
              <li>
                <Link to='/work#apply'>{t("FOOTER.WORKWITHUS.APPLY")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={styles.socials}>
        <div className={styles.socials_icons}>
          <div>
            <SocialButton social='instagram' />
          </div>
          <div>
            <SocialButton social='linkedin' />
          </div>
        </div>
        <p className={styles.socials_copy}>N5 Now © {t("FOOTER.RIGHTS")}</p>
        <p className={styles.socials_copy}>{t("FOOTER.DISTRIBUTIONCONTACT")}</p>
        <p className={styles.socials_privacy_policy}><a href="/privacy-policy/">{t("FOOTER.PRIVACYPOLICY")}</a></p>
        <p className={styles.socials_copy}>Costa Rica 5546, Buenos Aires, 5to piso</p>
      </section>
    </footer>
  );
};

export default Footer;
